import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface EditorLogState {
  value: string[];
}

const initialState: EditorLogState = {
  value: ["I am the default editorLog message"],
};

export const editorLogSlice = createSlice({
  name: "editorLog",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<string>) => {
      state.value.push(action.payload);
    },
    clear: (state) => {
      state.value = [];
    },
    override: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
  },
});

export const { add, clear, override } = editorLogSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEditorLog = (state: RootState) => state.editorLog.value;

export default editorLogSlice.reducer;
