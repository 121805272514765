import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface BabylonLogState {
  value: string[];
}

const initialState: BabylonLogState = {
  value: [],
};

export const babylonLogSlice = createSlice({
  name: "babylonLog",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<string>) => {
      state.value.push(action.payload);
    },
    clear: (state) => {
      state.value = [];
    },
    override: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
  },
});

export const { add, clear, override } = babylonLogSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBabylonLog = (state: RootState) => state.babylonLog.value;

export default babylonLogSlice.reducer;
