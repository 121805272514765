import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface DialogState {
  isOpen: boolean;
  messages: string[];
  charIndex: number;
  messageIndex: number;
}

const initialState: DialogState = {
  isOpen: false,
  messages: ["The moon rulz #1", "Let's fucking goooooo!!!!!!"],
  charIndex: 0,
  messageIndex: 0,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<string[]>) => {
      state.messages = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setCharIndex: (state, action: PayloadAction<number>) => {
      state.charIndex = action.payload;
    },
    setMessageIndex: (state, action: PayloadAction<number>) => {
      state.messageIndex = action.payload;
    },
  },
});

export const { setCharIndex, setIsOpen, setMessageIndex, setMessages } =
  dialogSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectDialog = (state: RootState) => state.dialog.dialogMessages;

export default dialogSlice.reducer;
