import React from "react";
import
    {
        BottomNavigation,
        Box,
        Container,
        Grid,
        Link,
        MenuItem,
        Typography,
    } from "@mui/material";

import { pages, links } from "utils/Lists";
import moonLadderLogo from "assets/astronaut-climbing-ladder-pixelart-teal.png";

/* <footer>
<Container maxWidth="lg">
    <Typography
        variant="body2"
        sx={{
            margin: "1rem 0",
            color: (theme) => theme.palette.bgMid.contrastText,
        }}
    >
        ©2022 Moon Ladder LLC. All rights reserved.
    </Typography>
</Container>
</footer> */

// Create a footer component
const Footer = () =>
{
    // Create a MUI footer
    return (
        <BottomNavigation sx={{ minHeight: "300px", backgroundColor: "black" }}>
            <Container
                maxWidth="lg"
                sx={{
                    padding: "2rem",
                    margin: "auto",
                }}
                className="footer-style-01"
            >
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={4} md={4} lg={4}>
                        <img
                            src={moonLadderLogo}
                            alt="Moon Ladder Logo"
                            id="logo"
                            width="64px"
                            height="64px"
                        />
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: "none", md: "flex" },
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                textDecoration: "none",
                            }}
                        >
                            Moon Ladder
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            href="mailto:info@moonladder.io"
                            underline="none"
                            sx={{
                                margin: "1rem 0",
                                color: (theme) =>
                                    theme.palette.bgDark.contrastText,
                                fontFamily: "rajdhani",
                            }}
                        >
                            Contact Us
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            sx={{
                                margin: "1rem 0",
                                color: (theme) =>
                                    theme.palette.bgDark.contrastText,
                            }}
                        >
                            ©2023 Moon Ladder LLC. All rights reserved.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </BottomNavigation>
    );
};

export default Footer;
