import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Link from "@mui/material/Link";

import NavbarMenu from "components/navbar/NavbarMenu";
import moonLadderLogo from "assets/astronaut-climbing-ladder-pixelart-teal.png";

import { pages, links } from "utils/Lists";

const companyFont = "rajdhani";

function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar
            id="navbar"
            position="static"
            sx={{ backgroundColor: (theme) => theme.palette.bgDark.main }}
        >
            <Container maxWidth="lg">
                <Toolbar id="toolbar" disableGutters>
                    <img
                        src={moonLadderLogo}
                        alt="Moon Ladder Logo"
                        id="logo"
                        width="64px"
                        height="64px"
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            marginLeft: "1rem",
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                        className="navbarText"
                    >
                        Moon Ladder
                    </Typography>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        Moon Ladder
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <NavbarMenu pages={pages} links={links} />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            marginLeft: "4rem",
                        }}
                    >
                        {pages.map((page, i) => (
                            <Link
                                href={links[i]}
                                underline="none"
                                sx={{
                                    ":before": { borderBottomColor: "red" },
                                    // underline when selected
                                    ":after": { borderBottomColor: "blue" },
                                }}
                            >
                                <MenuItem
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.bgDark.contrastText,
                                    }}
                                >
                                    {page}
                                </MenuItem>
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar;
