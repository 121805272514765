import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import
{
    BrowserRouter,
    Routes,
    Route,
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";
import "index.css";
import App from "app/App";
import { store } from "store/store";
import AboutPage from "routes/About";
import HomePage from "routes/Home";
import JoinPage from "routes/Join";
import CodePiratesPage from "routes/CodePirates";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LinkProps } from "@mui/material/Link";
import { deepPurple, teal } from "@mui/material/colors";

const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) =>
{
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
    interface Palette
    {
        bgDark: Palette["primary"];
        bgMid: Palette["primary"];
        bgLight: Palette["primary"];
    }
    interface PaletteOptions
    {
        bgDark: PaletteOptions["primary"];
        bgMid: PaletteOptions["primary"];
        bgLight: PaletteOptions["primary"];
    }
}

const theme = createTheme({
    palette: {
        primary: {
            // main: deepPurple[500],
            main: "#463191",
        },
        secondary: {
            main: "#5EDBA5",
        },
        info: {
            main: "#264d80",
        },
        bgDark: {
            // Amazon black: #131921
            main: "#131b21",
            contrastText: "#ddd",
        },
        bgMid: {
            // Amazon dark blue: #232f3e
            main: "#22323d",
            contrastText: "#fff",
        },
        bgLight: {
            main: "rgb(195, 205, 215)",
            contrastText: "#000",
        },
    },
    typography: {
        allVariants: {
            color: "#fff",
        },
        fontFamily: ["rajdhani", "sans-serif"].join(","),
        body1: {
            fontSize: "1.3rem",
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: "red",
                        transition: "0.2s",
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route path="/" element={<HomePage />} />
                            {/* <Route path="about" element={<AboutPage />} /> */}
                            {/* <Route path="join" element={<JoinPage />} /> */}
                            {/* <Route
                                path="codepirates"
                                element={<CodePiratesPage />}
                            /> */}
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
