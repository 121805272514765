import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "components/navbar/Navbar";
import Footer from "components/footer/Footer";
import { Container } from "@mui/material";
import CookieConsent from "react-cookie-consent";

import "app/App.css";

// Import fonts
import "@fontsource/im-fell-english-sc";
import "@fontsource/im-fell-english";
import "@fontsource/bebas-neue";
import "@fontsource/rajdhani";
import "@fontsource/nanum-gothic-coding";
import "@fontsource/ibm-plex-mono";

function App() {
    return (
        <body className="App">
            <Navbar />
            <Outlet />
            <Footer />
            <CookieConsent>
                We use cookies to give you the best online experience.
            </CookieConsent>
        </body>
    );
}

export default App;
