import { combineReducers } from "@reduxjs/toolkit";
import { History } from "history";
import babylonLogReducer from "store/babylonLogSlice";
import editorLogReducer from "store/editorLogSlice";
import dialogReducer from "store/dialogSlice";

// export interface State {
//   counter: ReturnType<typeof counterReducer>;
// }

const createRootReducer = (history: History) =>
  combineReducers({
    babylonLog: babylonLogReducer,
    dialog: dialogReducer,
    editorLog: editorLogReducer,
  });

export default createRootReducer;
