import React from "react";
import Typography from "@mui/material/Typography";
import { Container, Link } from "@mui/material";

const h5PaddingTop = "1.5rem";
const bodyPaddingBottom = "1.5rem";

const Home = () =>
{
    return (
        <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
            <Typography
                variant="h2"
                sx={{
                    paddingTop: "2rem",
                }}
            >
                Welcome to Moon Ladder Studios
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    paddingTop: "2rem",
                }}
            >
                Who are we?
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: `1rem 0 0 0`,
                }}
            >
                We are a work-for-hire game development company focused on DevOps and gameplay engineering. By leveraging a distributed team of Agile engineers and automating repetitive tasks with AI, we are able to deliver high-quality results with minimal overhead.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 1rem 0",
                }}
            >
                Our team has a wide range of software experience from large organizations, like Disney, Legendary Entertainment, and the U.S. Air Force, to small organizations, like Pocket Piñata, Trendy Entertainment, and Kobayashisoft.
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    paddingTop: "2rem",
                }}
            >
                Areas of Expertise
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    paddingTop: "2rem",
                }}
            >
                Game Engines
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                Our team has extensive experience with Unreal and Unity.
                <Typography
                    variant="body1"
                    sx={{
                        padding: "0 0 0 0",
                    }}
                >
                    <ul>
                        <li>
                            Unreal Engine
                        </li>
                        <li>
                            Unreal Editor for Fortnite (UEFN)
                        </li>
                        <li>
                            Unity
                        </li>
                    </ul>
                </Typography>
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    paddingTop: "2rem",
                }}
            >
                Security
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                The security of our customers' data and IP is a top priority for us. Security specialists we work with maintain cybersecurity certifications including ISO 27001 Lead Implementer, CISSP, and Security+. We have helped companies successfully implement software and cloud solutions in compliance with a variety of rigorous standards:
                <ul>
                    <li>
                        SOC 2
                    </li>
                    <li>
                        ISO 27001
                    </li>
                    <li>
                        GDPR
                    </li>
                    <li>
                        NIST 800-53
                    </li>
                </ul>
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    paddingTop: h5PaddingTop,
                }}
            >
                CI/CD
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                At Moon Ladder, we believe CI/CD is the key to Agile software development, not just with web applications, but with games as well. We have implemented CI/CD pipelines with a variety of tools.
                <Typography


                    variant="body1"
                    sx={{
                        padding: "0 0 0 0",
                    }}
                >
                    <ul>
                        <li>
                            Jenkins
                        </li>
                        <li>
                            GitHub Actions
                        </li>
                        <li>
                            GitLab CI
                        </li>
                        <li>
                            Bitbucket Pipelines
                        </li>
                        <li>
                            AWS CodePipeline
                        </li>
                    </ul>
                </Typography>
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    paddingTop: h5PaddingTop,
                }}
            >
                Cloud Providers
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                We work with all of the major cloud providers, although we specialize in AWS.
                <Typography
                    variant="body1"
                    sx={{
                        padding: "0 0 0 0",
                    }}
                >
                    <ul>
                        <li>
                            Amazon Web Services (AWS)
                        </li>
                        <li>
                            Azure
                        </li>
                        <li>
                            Google Cloud Platform (GCP)
                        </li>
                    </ul>
                </Typography>
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    paddingTop: h5PaddingTop,
                }}
            >
                Infrastructure as Code
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                We love Infrastructure as Code (IaC) tools, particularly HashiCorp's suite of products.
                <Typography
                    variant="body1"
                    sx={{
                        padding: "0 0 0 0",
                    }}
                >
                    <ul>
                        <li>
                            Terraform
                        </li>
                        <li>
                            Terragrunt
                        </li>
                        <li>
                            Packer
                        </li>
                        <li>
                            Ansible
                        </li>
                        <li>
                            Puppet
                        </li>
                    </ul>
                </Typography>
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    paddingTop: h5PaddingTop,
                }}
            >
                Containers
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                Whenever feasible, we prefer containerizing our servers and orchestrating them using Kubernetes.
                <Typography
                    variant="body1"
                    sx={{
                        padding: "0 0 0 0",
                    }}
                >
                    <ul>
                        <li>
                            Kubernetes
                        </li>
                        <li>
                            Docker
                        </li>
                        <li>
                            Helm
                        </li>
                        <li>
                            Rancher
                        </li>
                        <li>
                            ArgoCD
                        </li>
                    </ul>
                </Typography>
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    paddingTop: "2rem",
                }}
            >
                Clients
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                <Link
                    underline="none"
                    onClick={() =>
                        window.open("https://www.kobayashisoft.com/", "_blank")
                    }
                    sx={{
                        color: (theme) =>
                            theme.palette.bgDark.contrastText,
                    }}
                >
                    Kobayashisoft
                </Link>
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 0 0",
                }}
            >
                <Link
                    underline="none"
                    onClick={() =>
                        window.open("https://pocketpinata.com/", "_blank")
                    }
                    sx={{
                        color: (theme) =>
                            theme.palette.bgDark.contrastText,
                    }}
                >
                    Pocket Piñata
                </Link>
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    paddingTop: "3rem",
                }}
            >
                Contact Us
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    padding: "1rem 0 2rem 0",
                }}
            >
                Please{" "}
                <Link
                    href="mailto:info@moonladder.io"
                    underline="none"
                    sx={{
                        margin: "1rem 0",
                        color: (theme) =>
                            theme.palette.bgDark.contrastText,
                        fontFamily: "rajdhani",
                    }}
                >
                    send us an email
                </Link>
                {" "}if you're interested in working with us.
            </Typography>
        </Container>
    );
};

export default Home;
