import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";

export default function NavbarMenu(props: any) {
    const { pages, links } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <MenuIcon
                id="navbar-menu-icon"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            />
            <Menu
                id="navbar-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <nav>
                    {pages.map((page: any, i: number) => {
                        return (
                            <Link href={links[i]} underline="none">
                                <MenuItem onClick={handleClose}>
                                    {page}
                                </MenuItem>
                            </Link>
                        );
                    })}
                </nav>
            </Menu>
        </div>
    );
}
